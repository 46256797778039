<template>
  <div class="home">
    <div class="highlight">
      <div class="text is-size-4">
        <h1 class="title">IDS Metadata Broker</h1>
        <h2 class="subtitle">View the active registrations in this Metadata Broker</h2>
        <p class="has-text-grey content">
          The IDS Metadata Broker is a service for publishing and searching metadata of Connectors and resources between International Data Spaces Participants. In order to ensure the necessary interoperability and general interactions, an IDS Metadata Broker (like the App Store) is also defined as a specialized IDS Connector. The communication between an IDS Connector and an IDS Meta Data Broker is based on the same principles as any other Connector-Connector communication within the International Data Spaces. Still, an IDS Metadata Broker provides a collection of additional functionalities:
          <ul>
            <li>Indexing services in order to effectively and efficiently respond to queries and present known Connectors and other resources.</li>
            <li>Interfaces for Users or IDS-Messages to ensure access to the stored information.</li>
          </ul>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    connectors: {
      type: Array,
      required: true,
    },
    participants: {
      type: Array,
      required: true,
    },
  },
  computed: {
    agents: function () {
      return this.connectors
        .map((c) => {
          return c.agents.map((a) => {
            return {
              title: a.title,
              id: a.id,
              versions: a.versions,
              serviceprovider: this.matchParticipant(c.maintainer),
            };
          });
        })
        .flat();
    },
  },
  data() {
    return {
      connectorFilter: "",
      agentsFilter: "",
    };
  },
  methods: {},
};
</script>

<style lang="scss">
.hero.is-bold {
  position: relative;
  z-index: 10;
  background-color: white;
}
.tabs li.is-active a {
  color: #222;
}
.tabs.is-centered.is-large {
  position: relative;
  z-index: 10;
  background-color: white;
}
</style>

<style lang="scss" scoped>
@import "bulma/sass/utilities/_all.sass";
$zerow-green: #91BCA1;

.highlight {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5rem;
  .text {
    .title {
      font-weight: bold;
    }
    .subtitle {
      color: $zerow-green;
      font-weight: bold;
    }
    p {
      margin-bottom: 2rem;
    }
    width: 90%;
    padding: 1rem;
    position: relative;
    z-index: 12;
    @include from($desktop) {
      width: 70%;
    }
    @include from($widescreen) {
      width: 60%;
    }
    @include from($fullhd) {
      width: 50%;
    }
    p:nth-child(1) {
      padding-bottom: 1em;
    }
  }
}
.home {
  position: relative;
  min-height: 600px;
  .hero {
    position: relative;
    z-index: 9;
    width: 100%;
    .title {
      position: absolute;
      top: 15rem;
      z-index: 20;
      left: 10%;
      width: 80%;
      font-size: 4rem;
      @include from($tablet) {
        width: 80%;
        left: 10%;
        top: 20rem;
      }
      @include from($desktop) {
        width: 80%;
        left: 10%;
        top: 20rem;
      }
      @include from($widescreen) {
        width: 70%;
        left: 20%;
      }
      @include from($fullhd) {
        width: 70%;
        left: 15%;
        font-size: 5rem;
        top: 20rem;
      }
      @include from($fullhd*1.25) {
        font-size: 6rem;
        top: 25rem;
      }
    }
    figure {
      svg {
        min-height: 650px;
        .a {
          fill: none;
        }
        .b,
        .f,
        .i,
        .q {
          fill: #3ba7b6;
        }
        .c {
          fill: #74cee2;
        }
        .d {
          fill: #72bf44;
        }
        .e,
        .h {
          fill: #fff;
        }
        .e {
          opacity: 0.2;
        }
        .e,
        .f,
        .g,
        .h,
        .i,
        .j,
        .k,
        .l,
        .n,
        .o,
        .p,
        .q {
          isolation: isolate;
        }
        .f,
        .g,
        .j,
        .l,
        .o,
        .p {
          opacity: 0.8;
        }
        .g {
          fill: #506b5d;
        }
        .h,
        .k {
          opacity: 0.6;
        }
        .i {
          opacity: 0.1;
        }
        .j {
          fill: #13636f;
        }
        .k,
        .p {
          fill: #3e5878;
        }
        .l {
          fill: #2390ac;
        }
        .m {
          clip-path: url(#a);
        }
        .o {
          fill: #00545c;
        }
        .q {
          opacity: 0.69;
        }
      }
    }
  }
}
</style>
