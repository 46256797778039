<template>
  <section class="section">
    <div class="level">
      <div class="level-left">
        <h2 class="title is-4">Connectors</h2>
      </div>
      <div class="field level-right">
        <div class="control">
          <input
            class="input"
            type="text"
            v-model="connectorFilter"
            placeholder="Filter Connectors"
          />
        </div>
      </div>
    </div>
    <b-table
      :data="filterConnectors(connectors, connectorFilter)"
      :paginated="true"
      :per-page="25"
      :selected.sync="selectedRow"
      focusable
      >

      <b-table-column field="idsid" label="IDS ID" v-slot="props">
        {{ props.row.idsid }}
      </b-table-column>
      <b-table-column field="curator" label="Curator" v-slot="props">
        {{ props.row.curator }}
      </b-table-column>
      <b-table-column field="maintainer" label="Maintainer" v-slot="props">
        {{ props.row.maintainer }}
      </b-table-column>
      <b-table-column field="titles" label="Title" v-slot="props">
        {{ props.row.titles[0].value }}
      </b-table-column>
      <b-table-column field="descriptions" label="Description" v-slot="props">
        {{ props.row.descriptions[0].value }}
      </b-table-column>
    </b-table>
    <template v-if="selectedConnector">
      <h2 class="title is-4">Selected connector {{ selectedConnector['@id'] }}</h2>
      <JSONView
            style="overflow-x: auto"
            :data="selectedConnector"
            rootKey="Connector"
            :maxDepth="1"
          />
    </template>
  </section>
</template>

<style lang="scss">
$scsn-green: #72a7d1;
.table-wrapper {
    padding-bottom: 0.5rem;
}
.table tr.is-selected {
  background-color: $scsn-green;
}
</style>

<script>
import { JSONView } from 'vue-json-component';

export default {
  components: {
    JSONView
  },
  props: {
    connectors: {
      type: Array,
      required: true
    },
    connectorsFull: {
      type: Array,
      required: true
    },
    participants: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      connectorFilter: "",
      selectedRow: undefined
    };
  },
  computed: {
    selectedConnector() {
      if (this.selectedRow) {
        const connector = this.connectorsFull.filter(element => element['@id'] === this.selectedRow['idsid'])[0]
        return Object.keys(connector).sort().reduce(
          (obj, key) => { 
            obj[key] = connector[key]; 
            return obj;
          }, {})
      }
      return undefined
    }
  },
  methods: {
    getState: function (data, inputValue, keys = [], state = false) {
      for (const value of (keys.length) ? keys.map(key => data[key]) : Object.values(data)) {
        if (typeof value === 'object' && value !== null && Object.keys(value).length > 0 && state === false) {
          state = this.getState(value, inputValue, [], state);
        } else {
          if (state === false) {
            state = JSON.stringify(value).toLowerCase().includes(inputValue.toLowerCase());
          } else {
            return state;
          }
        }
      }
      return state;
    },
    filterConnectors: function () {
        return this.connectors.filter((element) => this.getState(element, this.connectorFilter, ["idsid", "titles", "descriptions", "curator", "maintainer"]));
    },
    selectConnector: function (idsid) {
      this.selectedConnector = this.connectorsFull.filter(element => element['@id'] === idsid)
    }
  }
}
</script>