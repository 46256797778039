<template>
  <div>
    <div v-if="!connectorsLoaded || !participantsLoaded" id="pageloader">
      <span class="title">Loading dashboard</span>
    </div>
    <template v-else>
      <section class="hero is-bold">
        <div class="hero-body py-2">
          <div class="container">
            <div class="level">
              <div class="level-left">
                <figure class="image">
                  <img src="@/assets/ZeroW.png" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="tabs is-centered is-large">
        <ul>
          <li v-bind:class="{ 'is-active': tab === 'home' }" v-on:click="tab = 'home'">
            <a>Home</a>
          </li>
          <li v-bind:class="{ 'is-active': tab === 'participants' }" v-on:click="tab = 'participants'">
            <a>Participants</a>
          </li>
          <li v-bind:class="{ 'is-active': tab === 'connectors' }" v-on:click="tab = 'connectors'">
            <a>Connectors</a>
          </li>
          <li v-if="credentials.username !== ''" v-bind:class="{ 'is-active': tab === 'admin' }"
            v-on:click="tab = 'admin'">
            <a>Admin</a>
          </li>
        </ul>
      </div>
      <div class="main" :class="{ 'container': tab !== 'home' }" style="min-height:750px;">
        <Home v-if="tab === 'home'" :connectors="connectors" :participants="participants" />
        <Participants v-if="tab === 'participants'" :connectors="connectors" :participants="participants"
          :participantsFull="participantsFull" />
        <Connectors v-if="tab === 'connectors'" :connectors="connectors" :connectorsFull="connectorsFull"
          :participants="participants" />
        <Admin v-if="tab === 'admin'" :credentials="credentials" @credentialChange="credentialsChange" />
      </div>
      <footer class="footer">
        <div class="content has-text-centered has-text-white">
          <img src="@/assets/tno-logo.png" />
          <p>&copy; 2023 ZeroW Broker</p>
        </div>
      </footer>
    </template>
  </div>
</template>

<script>
import Participants from "./components/Participants.vue";
import Connectors from "./components/Connectors.vue";
import Admin from "./components/Admin.vue";
import Home from "./components/Home.vue";

import axios from "axios";
const api = axios.create({
  baseURL: "/api/",
  timeout: 5000,
});

export default {
  components: {
    Participants,
    Connectors,
    Admin,
    Home,
  },
  data() {
    return {
      connectorsLoaded: false,
      participantsLoaded: false,
      tab: window.location.hash.replace('#', '') || "home",
      connectors: [],
      connectorsFull: [],
      participants: [],
      participantsFull: [],
      connectorTimer: 0,
      participantTimer: 0,
      credentials: {
        username: "",
        password: ""
      }
    };
  },
  watch: {
    tab: function (newTab) {
      window.location.hash = newTab;
    }
  },
  created: async function () {
    this.fetchConnectors();
    this.fetchConnectorsFull();
    this.fetchParticipants();
    this.fetchParticipantsFull();
    this.connectorTimer = setInterval(this.fetchConnectors, 5 * 60 * 1000);
    this.participantTimer = setInterval(this.fetchParticipants, 5 * 60 * 1000);
    if (localStorage.username !== undefined && localStorage.password !== undefined) {
      this.credentials.username = localStorage.username;
      this.credentials.password = localStorage.password;
    }
  },
  methods: {
    credentialsChange: function (credentials) {
      localStorage.username = credentials.username;
      localStorage.password = credentials.password;
      this.credentials = credentials;
    },
    fetchConnectors: async function () {
      const connectors = await api.get("connectors");
      this.connectors = connectors.data;
      this.connectorsLoaded = true;
    },
    fetchConnectorsFull: async function () {
      const connectorsFull = await api.get("connectors/full");
      this.connectorsFull = connectorsFull.data;
    },
    fetchParticipants: async function () {
      const participants = await api.get("participants");
      this.participants = participants.data;
      this.participantsLoaded = true;
    },
    fetchParticipantsFull: async function () {
      const participantsFull = await api.get("participants/full");
      this.participantsFull = participantsFull.data;
    },
  },
  beforeDestroy: function () {
    clearInterval(this.connectorTimer);
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap');

$zerow-green: #91BCA1;

$family-sans-serif: "Open Sans", sans-serif;
$text-strong: #222;
$tabs-link-color: $zerow-green;
$tabs-link-hover-color: darken($zerow-green, 20%);
$tabs-link-hover-border-bottom-color: darken($zerow-green, 20%);
$link: $zerow-green;
$dark: #222;
$card-shadow: 0 0.5em 1em -0.125em rgba($zerow-green, 0.15),
  0 0px 0 1px rgba($zerow-green, 0.05);
$card-header-shadow: 0 0.125em 0.25em rgba($zerow-green, 0.1);
$footer-background-color: black;
$table-row-active-color: $zerow-green;
$footer-padding: 0rem 1.5rem 0rem;

@import "~bulma/bulma";
@import "~buefy/src/scss/buefy";

tr.is-selected {
  background-color: $zerow-green !important;
  color: white !important;
}

html {
  background-color: black;
}

body {
  background-color: white;
}

.tabs li.is-active a {
  color: #222;
  border-bottom-color: #222;
}

.hero {
  &.is-bold {
    background-color: white;
  }

  img {
    max-height: 4rem;

    @include from($tablet) {
      max-height: 9rem;
    }
  }

  .title {
    color: black;
    font-size: 3rem;
    text-align: center;

    @include from($tablet) {
      text-align: left;
    }
  }
}

footer {
  position: relative;
  z-index: 20;

  >.content {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      padding-right: 3em;
      max-height: 10rem;

      @include from($desktop) {
        padding-right: 5em;
      }
    }
  }
}

.card-columns {
  columns: auto 1;
  margin-bottom: 3em;

  @include from($desktop) {
    columns: auto 2;
  }

  @include from($fullhd) {
    columns: auto 3;
  }

  .card-column {
    break-inside: avoid;
    margin-bottom: 1em;

    figure {
      display: flex;
      justify-content: center;
      align-items: center;

      &.is-2by1>div>div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

        >img {
          max-width: 100%;
          width: auto !important;
          height: auto !important;
          max-height: 100%;
          position: relative;
          top: auto;
          left: auto;
          margin-top: 0;
        }
      }
    }
  }
}

span.tag {
  margin: 0.2em;
}

#pageloader {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  position: fixed;
  padding-top: 2em;
  background: hsl(217, 71%, 53%);
  z-index: 999998;
  transition: transform 0.35s ease-out, -webkit-transform 0.35s ease-out;
  will-change: transform;

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    border-radius: 100%;
    content: "";
    z-index: 9999;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    box-sizing: border-box;
    border: 0 solid #fff;
    -webkit-animation: loader-figure 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
    animation: loader-figure 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .title {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    margin: 2em 0 0 0;
    font-size: 0.875em;
    letter-spacing: 0.1em;
    line-height: 1.5em;
    color: #fff;
    white-space: nowrap;
  }
}

@-webkit-keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-moz-keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-o-keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}
</style>